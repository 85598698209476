import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PoliticaPrivacidad = () => (
  <Layout>
    <SEO title="Política de privacidad" />

    <div className="page-legal container">
    	<h1 className="main-title">Política de privacidad y protección de datos</h1>
		
		<p>En cumplimiento del Capítulo II de la ley 34/2002, LSSICE, informamos que la presente página web es propiedad de Dimedix Surgical S.L., de ahora en adelante también el 
		PRESTADOR o DIMEDIX, con CIF: B65246084, dirección postal: Pj Santa Fe Num. 6p. 1 Pta. 4  08191 Rubí (Barcelona), teléfono de contacto: 676 231 243, y email: info@dimedix.es.</p>

		<p>DIMEDIX, como responsable del Sitio web, de conformidad con lo que dispone el Reglamento (UE) 2016/679 de 27 de abril de 2016 (RGPD) relativo a la protección 
		de las personas físicas en cuanto al tratamiento de datos personales y a la libre circulación de estos datos y demás normativa vigente en materia de protección de datos personales, 
		y por la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico (LSSICE), le informa de que tiene implementadas las medidas de seguridad 
		necesarias, de índole técnica y organizativas, para garantizar y proteger la confidencialidad, integridad y disponibilidad de los datos introducidos.</p>
		        
		<p>A efectos de lo que prevé la LOPD, informamos que los datos que voluntariamente nos está facilitando serán incorporadas a nuestros sistemas de información con el fin de realizar 
		las gestiones comerciales y administrativas necesarias con los usuarios de la web; Las operaciones previstas para realizar los tratamientos son los siguientes: responder a las consultas
		 y/o proporcionar informaciones requeridas por el Usuario; realizar las prestaciones de servicios y/o productos contratados o subscritos por el Usuario; realizar todas aquellas actividades 
		 propias de DIMEDIX por el presente aviso legal reseñadas y remitir el boletín de noticias de la página web.</p>
		<p>El firmante garantiza la veracidad de los datos aportados y se compromete a comunicar cualquier cambio que se produzca en los mismos.</p>
		<p>El Prestador, mediante asterisco (*) en las casillas correspondientes del formulario de contacto, le informa de esta obligatoriedad al Usuario, indicando qué datos son necesarios. 
		Mediante la indicación e introducción de los datos, el Usuario otorga el consentimiento inequívoco a DIMEDIX para que proceda al tratamiento de los datos facilitados en pro de las 
		finalidades mencionadas.</p>
		<p>El no facilitar los datos personales solicitados o el no aceptar la presente política de protección de datos supone la imposibilidad de subscribirse, registrarse o recibir información 
		de los productos y servicios del Prestador.</p>
		 
		<p>En conformidad con lo que disponen las normativas vigentes en protección de datos personales, el Prestador está cumpliendo con todas las disposiciones de las normativas RGPD y LOPD 
		para el tratamiento de los datos personales de su responsabilidad, y manifiestamente con los principios descritos al art. 5 del RGPD y al art. 4 de la LOPD, por los cuales son tratados 
		de manera lícita, leal y transparente en relación con el interesado y adecuadas, pertinentes y limitados al que es necesario en relación con los fines para los cuales son tratados.</p>
		 
		<p>El RESPONSABLE garantiza que ha implementado políticas técnicas y organizativas apropiadas para aplicar las medidas de seguridad que establecen el RGPD y la LOPD para proteger los 
		derechos y libertades de los Usuarios.</p>
		 
		<p>De acuerdo con estas normativas, pues, le informamos que tiene derecho a solicitar el acceso, rectificación, portabilidad y supresión de sus datos y la limitación y oposición a su 
		tratamiento dirigiéndose a Pj Santa Fe Num. 6p. 1 Pta. 4  08191 Rubí (Barcelona), enviando un correo electrónico a info@dimedix.es, indicando como Asunto: “LOPD, Derechos ARCO”, y adjuntando 
		fotocopia de su DNI o cualquier medio análogo en derecho, tal y como indica la ley. Tiene derecho a retirar el consentimiento prestado en cualquier momento. La retirada del consentimiento 
		no afectará a la licitud del tratamiento efectuado antes de la retirada del consentimiento. También tiene derecho a presentar una reclamación ante la autoridad de control si considera que 
		pueden haberse visto vulnerados sus derechos en relación a la protección de sus datos (agpd.es).</p>
		 
		<h2 className="title">Confidencialidad y cesión de datos a terceros</h2>
		<p>Los datos que nos facilite se tratarán de forma confidencial. El Prestador ha adoptado todas las medidas técnicas y organizativas y todos los niveles de protección necesarios para garantizar 
		la seguridad en el tratamiento de los datos y evitar su alteración, pérdida, robo, tratamiento o acceso no autorizado, de acuerdo el estado de la tecnología y naturaleza de los datos almacenados. 
		Así mismo, se garantiza también que el tratamiento y registro en ficheros, programas, sistemas o equipos, locales y centros cumplen con los requisitos y condiciones de integridad y seguridad 
		establecidas en la normativa vigente.</p>
		<p>DIMEDIX no cederá los datos personales a terceros, excepto por obligación legal. Sin embargo, en el caso de ser cedidos a algún tercero se produciría una información previa solicitando 
		el consentimiento expreso para tal cesión. La entidad responsable de la base de datos, así como los que intervengan en cualquier fase del tratamiento y/o las entidades a quienes se los haya 
		comunicado -en todo caso siempre con la correspondiente autorización otorgada por el usuario-, están obligadas a observar el secreto profesional y a la adopción de los niveles de protección 
		y las medidas técnicas y organizativas necesarias a su alcance para garantizar la seguridad de los datos de carácter personal, evitando, dentro de lo posible, accesos no autorizados, 
		modificaciones ilícitas, sustracciones y/o la pérdida de los datos, con objeto de procurar el correspondiente nivel de seguridad de los ficheros del Prestador, según la naturaleza y 
		sensibilidad de los datos facilitados por los usuarios del presente Sitio web.</p>
		 
		<h2 className="title">Aceptación y consentimiento</h2>
		<p>El Usuario declara haber sido informado de las condiciones sobre protección de datos de carácter personal, aceptando y consintiendo el tratamiento automatizado de los mismos por 
		parte de DIMEDIX en la forma y para las finalidades indicadas en la presente Política de Protección de Datos Personales.</p>
		<p>A través de esta Política de Privacidad le informamos que las fotografías que estén colgadas en la web son propiedad de DIMEDIX, incluyendo las de los menores, en las que, para 
		la obtención de estas, se ha obtenido el consentimiento previo de los padres, tutores o representantes legales mediante la firma de los formularios realizados al efecto por los centros 
		en los cuales los menores forman parte. Sin embargo, los padres, tutores o representantes de los menores, como titulares del ejercicio de los derechos de estos, y siempre previo requerimiento 
		formal por escrito, pueden indicar la negativa al uso de la imagen del menor; en este caso, la imagen se mostrará pixelada.</p>
		 
		<h2 className="title">Exactitud y veracidad de los datos</h2>
		<p>El usuario es el único responsable de la veracidad y corrección de los datos que remita a DIMEDIX, exonerando al Prestador de cualquier responsabilidad al respeto. 
		Los usuarios garantizan y responden, en cualquier caso, de la exactitud, vigencia y autenticidad de los datos personales facilitados, y se comprometen a mantenerlos debidamente 
		actualizados. El usuario acepta proporcionar información completa y correcta en el formulario de registro o suscripción.</p>
		 
		<h2 className="title">Contenido de la web y enlaces</h2>
		<p>DIMEDIX se reserva el derecho a actualizar, modificar o eliminar la información contenida en la web, pudiendo, incluso, limitar o no permitir el acceso a la información.</p>
		<p>DIMEDIX no asume ningún tipo de responsabilidad por la información contenida en las Webs de terceros a las que se pueda acceder por los «links» o enlaces desde cualquier página Web 
		propiedad del Prestador.</p>
		<p>La presencia de «links» o enlaces sólo tienen finalidad informativa y en ningún caso supone ninguna sugerencia, invitación o reconocimiento sobre los mismos.</p>
		 		 
		<h2 className="title">Cambios en la presente política de privacidad</h2>
		<p>DIMEDIX se reserva el derecho a modificar la presente política para adaptarla a novedades legislativas o jurisprudenciales.</p>
		 
		<h2 className="title">Correos comerciales</h2>
		<p>De acuerdo con la LSSICE, DIMEDIX no realiza prácticas de SPAM, por lo tanto, no envía correos comerciales por e-mail que no hayan sido previamente solicitados o autorizados por el Usuario. 
		En consecuencia, en cada uno de los formularios de la Página Web, el Usuario tiene la posibilidad de dar su consentimiento expreso para recibir nuestro Boletín, con independencia de la 
		información comercial puntualmente solicitada.</p>
		
		<h2 className="title">Legislación</h2>
		<p>A todos los efectos las relaciones entre DIMEDIX con los Usuarios de sus servicios telemáticos, presentes en esta Web, están sometidos a la legislación y jurisdicción española a la que 
		se someten expresamente las partes, siendo competentes por la resolución de todos los conflictos derivados o relacionados con su uso los Juzgados y Tribunales de Barcelona.</p>



    </div>
  </Layout>
)

export default PoliticaPrivacidad